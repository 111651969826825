var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper-container"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"large-image-container"},[_vm._m(2),_vm._m(3),_c('div',{staticClass:"large-image-h646-container"},_vm._l((_vm.bigImages),function(src){return _c('div',{key:src,staticClass:"large-image-h646"},[_c('img',{attrs:{"src":src}})])}),0),_vm._m(4)]),_vm._m(5),_vm._m(6),_vm._m(7),_c('action-sheet',{attrs:{"module":"新零售营销赋能"},scopedSlots:_vm._u([{key:"extra-title",fn:function(){return [_c('div',{staticClass:"extra-title"},[_vm._v("相关文章")])]},proxy:true}])})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"describe-container-box"},[_c('div',{staticClass:"title"},[_vm._v("营销赋能·方案详情")]),_c('div',{staticClass:"sub-title"},[_vm._v("营销赋能增长策略")]),_c('div',{staticClass:"large-paragraph-describe"},[_vm._v(" 全球蛙深耕品牌营销增长，精准把握供销两端需求，发挥数据、渠道、客群等优质资源，高效链接品牌商和零售商，打造全域全渠道全场景营销体系，通过支付补贴、百万爆品、导购分销、品牌派券、定制营销“1+4”的战略规划，推出支付券、品牌券、主题营销、爆品打造、导购大赛、新品推广等综合营销模式，助力品牌商、零售商通过数智驱动实现品销闭环，实现商超零售生态的业绩倍增。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"marketing-power-tag"},[_c('div',[_vm._v("“1+4”品牌营销增长路径")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"large-image-page-header"},[_c('div',[_vm._v("1+4品牌服务体系")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"large-image-page-text"},[_c('div',[_vm._v("节日品牌派券·月月百万爆款·周周导购分销")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"large-image-page-text",staticStyle:{"margin-top":"-5px"}},[_c('div',[_vm._v("支付营销·支付加码助力零售商/品牌商实现爆破式增长")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"func-module-container"},[_c('div',{staticClass:"func-title"},[_vm._v("营销赋能·应用案例")]),_c('div',{staticClass:"func-sub-title"},[_vm._v("品牌+支付双营销，助力商超新增长")]),_c('div',{staticClass:"func-describe"},[_vm._v(" 全球蛙针对当前市场新环境下品牌商对会员运营、渠道开拓和新品推广等新需求，围绕“增长为王 价值共创”的理念，联合支付侧、品牌侧、零售侧共同打造百万爆品营销。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"large-image-h618"},[_c('img',{attrs:{"src":require("@/image/retail/img_bj_market@3x.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"large-image-h818",staticStyle:{"margin-bottom":"20px"}},[_c('img',{attrs:{"src":require("@/image/retail/img_sc_market@3x.png")}})])
}]

export { render, staticRenderFns }