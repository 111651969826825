<template>
  <div class="wrapper-container">
    <div class="describe-container-box">
      <div class="title">营销赋能·方案详情</div>
      <div class="sub-title">营销赋能增长策略</div>
      <div class="large-paragraph-describe">
        全球蛙深耕品牌营销增长，精准把握供销两端需求，发挥数据、渠道、客群等优质资源，高效链接品牌商和零售商，打造全域全渠道全场景营销体系，通过支付补贴、百万爆品、导购分销、品牌派券、定制营销“1+4”的战略规划，推出支付券、品牌券、主题营销、爆品打造、导购大赛、新品推广等综合营销模式，助力品牌商、零售商通过数智驱动实现品销闭环，实现商超零售生态的业绩倍增。
      </div>
    </div>
    <div class="marketing-power-tag">
      <div>“1+4”品牌营销增长路径</div>
    </div>
    <div class="large-image-container">
      <div class="large-image-page-header">
        <div>1+4品牌服务体系</div>
      </div>
      <div class="large-image-page-text">
        <div>节日品牌派券·月月百万爆款·周周导购分销</div>
      </div>
      <div class="large-image-h646-container">
        <div class="large-image-h646" v-for="src in bigImages" :key="src">
          <img :src="src" />
        </div>
      </div>
      <div class="large-image-page-text" style="margin-top: -5px">
        <div>支付营销·支付加码助力零售商/品牌商实现爆破式增长</div>
      </div>
    </div>
    <div class="func-module-container">
      <div class="func-title">营销赋能·应用案例</div>
      <div class="func-sub-title">品牌+支付双营销，助力商超新增长</div>
      <div class="func-describe">
        全球蛙针对当前市场新环境下品牌商对会员运营、渠道开拓和新品推广等新需求，围绕“增长为王
        价值共创”的理念，联合支付侧、品牌侧、零售侧共同打造百万爆品营销。
      </div>
    </div>
    <div class="large-image-h618">
      <img src="@/image/retail/img_bj_market@3x.png" />
    </div>
    <div class="large-image-h818" style="margin-bottom: 20px">
      <img src="@/image/retail/img_sc_market@3x.png" />
    </div>
    <action-sheet module="新零售营销赋能">
      <template #extra-title>
        <div class="extra-title">相关文章</div>
      </template>
    </action-sheet>
  </div>
</template>

<script>
export default {
  data: () => ({
    bigImages: [
      require("@/image/retail/img_explosives_market@3x.png"),
      require("@/image/retail/img_guide_market@3x.png"),
      require("@/image/retail/img_brand_market@3x.png"),
      require("@/image/retail/img_marketing_market@3x.png"),
    ],
    operatePowerBigImages: [
      require("@/image/retail/img_mth_operate@3x.png"),
      require("@/image/retail/img_csf_operate@3x.png"),
      require("@/image/retail/img_mdj_operate@3x.png"),
    ],
  }),
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/solution.common.scss";
.large-paragraph-describe {
  margin-bottom: 0 !important;
}
.large-image-container {
  border-radius: 48px 48px 0 0;
  padding-top: 0 !important;
  padding-bottom: 60px !important;
}
.large-image-h646-container {
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  .large-image-h646 {
    margin-bottom: 50px;
    img {
      width: 328px;
      height: 646px;
    }
  }
  .large-image-h646:nth-of-type(odd) {
    margin-left: 30px;
  }
  .large-image-h646:nth-of-type(even) {
    margin-left: 34px;
  }
}
.func-module-container {
  background-color: transparent !important;
}
.large-image-h720:nth-of-type(2) {
  margin-bottom: 20px;
}
</style>
